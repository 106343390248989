import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { Form, Field } from 'react-final-form'


export default class ContactForm extends React.Component {
    state = {
        formSuccess: false,
        formFail: false,
    }

    onSubmit = async values => {
        // window.alert(JSON.stringify(values, 0, 2));
        return fetch('https://qfs1vhecci.execute-api.ap-southeast-1.amazonaws.com/uat/spkl-email-function', { 
            mode: 'no-cors',
            method: 'POST', 
            body: JSON.stringify(values), 
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((res) => {
            this.setState({ formSuccess: true });
        }).catch((err)=>{ 
            //console.log(err)
            this.setState({ formFail: true });
        })
    }

    render() {
        return (
            <Layout bodyClass="page-contact">
    <SEO title="Contact" />
     <div className="wr-contact">
        <section id="contact" name="contact">
          <div>
            <div className="contact-form">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="offset-top">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                      <div className="contact-block">
                        <div className="section-header">
                          {/* <p className="btn btn-subtitle">Contact</p> */}
                          <h2 className="title-3">We'd Love to Hear From You</h2>
                        </div>
                        <Form
                            onSubmit={this.onSubmit}
                            validate={values => {
                                const errors = {};
                                if (!values.name) {
                                errors.name = "Required";
                                }
                                if (!values.from) {
                                errors.from = "Required";
                                } 
                                // else if () {
                                //     errors.from = "Valid Email Required";
                                // }
                                if (!values.subject) {
                                errors.subject = "Required";
                                }
                                if (!values.message) {
                                errors.message = "Required";
                                }
                                return errors;
                            }}
                            initialValues={{  }}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>
                                <div className="wr-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                {/* <label>First Name</label> */}
                                                <Field name="name">
                                                    {({ input, meta }) => (
                                                        <div>
                                                        {/* <label>Name</label> */}
                                                        <input {...input} type="text" className="form-control" placeholder="Full Name" />
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Field name="from">
                                                        {({ input, meta }) => (
                                                            <div>
                                                            {/* <label>Email</label> */}
                                                            <input {...input} type="email" className="form-control" placeholder="Email" />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Field name="subject">
                                                    {({ input, meta }) => (
                                                        <div>
                                                        {/* <label>Subject</label> */}
                                                        <input {...input} type="text" className="form-control" placeholder="Subject" />
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Field name="message">
                                                    {({ input, meta }) => (
                                                        <div>
                                                        <textarea {...input} className="form-control" placeholder="Message" />
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                                {/* <Field name="message" className="form-control" component="textarea" placeholder="Message" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-button buttons">
                                        <button className="button btn-primary btn-lg" type="submit" disabled={submitting}>
                                        Submit
                                        </button>
                                    </div>  
                                </div>
                                <div className={this.state.formSuccess ? 'form-msg success show' : 'form-msg success'}>
                                    <span>Thanks for your message! We'll be in touch with you shortly.</span>
                                </div>
                                <div className={this.state.formFail ? 'form-msg fail show' : 'form-msg fail'}>
                                    <span>There was a problem sending your message. Please try again.</span>
                                </div>
                                </form>
                            )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  </Layout>
          )
    }
}




